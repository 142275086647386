<template>
  <div :class="$style.container">
    <footer :class="$style.footer">
      <router-link to="/" :class="$style.logo">
        <img
          :class="$style.logo__img"
          src="@/assets/images/logo-full.png"
          alt="桐生タイムス"
        />
      </router-link>
      <Spacer :y="3"/>
      <p :class="$style.text">
        群馬県東部の桐生市・みどり市を主要エリアとする日刊（夕刊）紙です。
        <br />
        1945年の創刊以来、市民とともに歩む新聞として、地域密着の紙面づくりを行っています。
      </p>
      <Spacer :y="7" :sm-y="6"/>
      <div :class="$style.inner">
        <div :class="$style.innerItem">
          <div :class="$style.title">お問い合わせ</div>
          <Spacer :y="3" :sm-y="2"/>
          <router-link :class="$style.contactLink" to="/contact/">お問い合わせフォームはこちら</router-link>
        </div>
        <Spacer :sm-y="6"/>
        <div :class="$style.innerItem">
          <div :class="$style.title">Social Network</div>
          <Spacer :sm-y="2"/>
          <ul :class="$style.social">
            <li>
              <a href="https://www.facebook.com/kiryutimes/" target="blank">
                <img src="@/assets/images/social/facebook.png" alt="">
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/kiryutimes/" target="blank">
                <img src="@/assets/images/social/instagram.png" alt="">
              </a>
            </li>
            <li>
              <a href="https://twitter.com/kiryutimes/" target="blank">
                <img src="@/assets/images/social/twitter.png" alt="">
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Spacer :y="10"/>
      <div style="text-align: center;">
        <ul :class="$style.pages">
          <li>
            <router-link
              to="/policy/">個人情報保護方針</router-link>
          </li>
          <Spacer :x="4"/>
          <li>
            <router-link
              to="/terms/">利用規約</router-link>
          </li>
          <Spacer :x="4"/>
          <li>
            <router-link
            to="/transactionLaw/">特定商取引法に基づく表示</router-link>
          </li>
          <Spacer :x="4"/>
          <li><a>R2 事業再構築 機 - 1</a></li>
        </ul>
      </div>
      <Spacer :y="10"/>
      <div :class="$style.copyright">（C）1945 桐生タイムス社　Powered by <a href="https://cicac.jp/" target="blank">CICAC Inc.</a></div>
    </footer>
  </div>
</template>

<script>
import Spacer from '@/components/Spacer';

export default {
  name: 'GlobalFooter',
  components: {
    Spacer,
  },
};
</script>

<style lang="scss" module>
.container {
  width: 100%;
  padding: 0 var(--g);
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: calc(var(--s) * 2);
}

.logo {
  width: 324px;
}

.text {
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}

.inner {
  display: flex;
  justify-content: center;
  width: 100%;
}

.innerItem {
  width: 50%;
  text-align: center;
}

.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
}

.social {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  height: 100%;

  li {
    width: 32px;
  }
}

.pages {
  display: flex;
  > li {
    color: var(--font-gray);
  }
  a {
    color: var(--font-gray);
    &:hover {
      text-decoration: underline;
    }
  }
}

.contactLink {
  color: var(--font-gray);
  text-align: center;
  &:hover {
    color: var(--font-gray);
    text-decoration: underline;
  }
}

.copyright {
  font-size: 11px;
  line-height: 1.6;
  color: var(--font-gray);
  a {
    color: var(--font-gray);
  }
}

@include sm-view {
  .footer {
    width: 100%;
  }

  .logo {
    width: 220px;
  }

  .inner {
    flex-direction: column;
    align-items: center;
  }

  .innerItem {
    width: 100%;
  }

  .pages {
    flex-direction: column;
    text-align: center;
  }
}
</style>
