<template>
  <div class="login container-xl">
    <div class="login_content">
      <spacer :y="4"/>
      <form
        class="form_login form"
        autocomplete="off">
        <h2 :class="$style.title">新規会員登録</h2>
        <spacer :y="4"/>
        <div :class="$style.form">
          <div :class="$style.input">
            <input
              id="f_name"
              name="f_name"
              type="text"
              v-model="$v.lastName.$model"
              v-bind:class="{ input_error: validationFlags.lastName }"
              v-on:input="invalidsCheck('lastName', $v.lastName.$invalid)"
              placeholder="姓">
          </div>
          <spacer :y="1"/>
          <p
            :class="$style.message"
            v-if="$v.lastName.$dirty
              && $v.lastName.$invalid">
              名字を入力してください
          </p>
          <spacer :y="2"/>
          <div :class="$style.input">
            <input
            id="l_name"
            name="l_name"
            type="text"
            v-model="$v.firstName.$model"
            v-bind:class="{ input_error: validationFlags.firstName }"
            v-on:input="invalidsCheck('firstName', $v.firstName.$invalid)"
            placeholder="名">
          </div>
          <spacer :y="1"/>
          <p
          :class="$style.message"
          v-if="$v.firstName.$dirty
            && $v.firstName.$invalid">
            氏名を入力してください
          </p>
          <spacer :y="2"/>
        </div>

        <spacer :y="1"/>
        <div
          :class="$style.form">
          <div :class="$style.input">
            <input
              id="mail"
              class=""
              type="email"
              name="mail"
              placeholder="メールアドレス"
              v-model.trim="$v.mail.$model"
              v-bind:disabled="flg.regist"
              v-bind:class="{ input_error: validationFlags.mail }"
              v-on:input="invalidsCheck('mail', $v.mail.$invalid)">
            <span class="slide-in"></span>
          </div>
          <spacer :y="1"/>
          <p
            :class="$style.message"
            v-if="$v.mail.$dirty
              && $v.mail.email.$invalid">
              正しいメールアドレスの形式で入力してください
          </p>
          <p
            :class="$style.message"
            v-if="!flg.exitst && flg.regist">
            ご入力いただいたメールアドレスにメールを送信しました。<br>メールに記載されているURLからパスワードを登録してください。
          </p>
          <p
            :class="$style.message"
            v-if="flg.exists && !flg.regist">
            送信いただいたメールアドレスは登録済みです。<br>
            <a href="/login/" class="link">ログインページ</a>
            よりログインしてください。<br>パスワード設定がまだの場合は、メールアドレス登録時に送信されたメールに記載のURLよりパスワード設定を行ってください。
          </p>
          <spacer :y="4"/>
          <div
            :class="$style.wrap"
            v-if="!flg.regist">
            <button
              :class="[$style.btn, !submitFlag ? $style.disabledSubmit : '']"
              @click="submit"
              v-bind:disabled="!submitFlag">
            送信</button>
          </div>
          <spacer :y="4"/>
          <div :class="$style.form">
            <router-link :class="$style.lgnlink" to="/subscriptions/">有料プランについて</router-link>
          </div>

        </div>

      </form>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import Spacer from '@/components/Spacer.vue';

export default {
  name: 'signup',
  components: {
    Spacer,
  },
  data() {
    return {
      flg: {
        exists: false,
        regist: false,
        error: false,
      },
      validationFlags: {
        mail: false,
        lastName: false,
        firstName: false,
      },
      invalids: {
        mail: true,
        lastName: true,
        firstName: true,
      },
      // 有効期限切れでリダイレクトされた
      isExpired: false,
    };
  },
  created() {},
  setup() {
    const mail = ref('');
    const lastName = ref('');
    const firstName = ref('');

    const rules = {
      // メールは必須・email形式
      mail: { required, email },
      lastName: { required },
      firstName: { required },
    };

    const $v = useVuelidate(rules, { mail, lastName, firstName });

    return {
      mail,
      lastName,
      firstName,
      $v,
    };
  },
  computed: {
    ...mapState(['user']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.mail && !this.invalids.lastName && !this.invalids.firstName;
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();

      this.showLoading();

      // validateチェックは入力時に行われてる
      const data = {
        flag: 10,
        email: this.mail,
        last_name: this.lastName,
        first_name: this.firstName,
        role: 1,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/register',
        data,
      })
        .then((response) => {
          const res = response.data;
          console.log(res);
          // 初期化
          this.flg.regist = false;
          this.flg.exists = false;
          // 初期登録
          if (!res.exists) this.flg.regist = true;
          // 登録済み
          else this.flg.exists = true;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  display: flex;
  justify-content: center;
}

.title {
  text-align: center;
  color: var(--orange-main);
}

.form {
  text-align: center;
}

.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
}

.message {
  font-size: 12px;
  text-align: center;
}
.input {
  margin: 0 auto;
  width: 400px;
  max-width: 100%;
  input {
    width: 100%;
    padding: 13px;
    border: none;
    background-color: var(--gray-sub);
    outline: none;
    border-bottom: 1px solid var(--gray);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
.disabledSubmit {
  opacity: 0.5;
  cursor: default;
}
.lgnlink {
  border: 1px solid rgb(26, 34, 61);
  color: rgb(26, 34, 61);
  background-color: #fff;
  text-decoration: none;
  &:hover {
    color: inherit
  }
}
.btn,.lgnlink {
  display: inline-block;
  border-radius: 22px;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
  font-weight: bold;
}
</style>
